import React, { useState, createContext } from 'react';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import ThemeContext from './ThemeContext';
import './i18n.js';

const overrides = {
    MUIRichTextEditor: {
        root: {
            width: "100%",
            border: "1px solid #bdbdbd",
            borderRadius: '4px',
            boxSizing: 'border-box'
        },
        editorContainer: {
            padding: '0.6rem',
            margin: 0,
            boxSizing: 'border-box'
        },
        placeHolder: {
            position: 'relative'
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: '0.5rem',
            paddingBottom: '0.5rem',
            borderBottom: '1px solid #e0e0e0'
        }
    }
}

const themes = {
    SEA: responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: '#454f8c'
            },
            secondary: {
                main: '#B80C09'
            },
            chat: {
                main: '#707BBB'
            },
        },
        overrides
    })),
    CPT: responsiveFontSizes(createMuiTheme({
        palette: {
        },
        overrides
    })),
    PFM: responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: '#6C54A4'
            },
            secondary: {
                main: '#EE6352'
            },
            chat: {
                main: '#707BBB'
            },
        },
        overrides
    })),
    VTV: responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: 'rgb(224,174,68)'//'#6C54A4'
            },
            // primary: {
            //     main: '#be8b1f'
            // },
            secondary: {
                main: '#B91372'//'#D34F73'
            },
        },
        overrides
    })),
    EWX: responsiveFontSizes(createMuiTheme({
        palette: {
            primary: {
                main: '#0099ff'
            },
            secondary: {
                main: '#D64045' //'#E26D5C'
            },
        },
        overrides
    }))
};

const Root = () => {

    const [team, setTeam] = useState('PFM');

    return (
        <ThemeContext.Provider value={{ setTeam }}>
            <ThemeProvider theme={themes[team]}>
                <Router>
                    <AppRoutes />
                </Router>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

export default Root;